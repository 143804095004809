import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [0,2,7];

export const dictionary = {
		"/(app)": [~13,[2]],
		"/(app)/account/billing": [14,[2,3]],
		"/(app)/account/integrations": [15,[2,3]],
		"/(app)/account/notifications": [16,[2,3]],
		"/(app)/account/organization": [~17,[2,3]],
		"/(app)/account/profile": [18,[2,3]],
		"/(app)/account/update-email": [19,[2,3,4]],
		"/(app)/account/update-password": [20,[2,3,5]],
		"/(app)/admin": [~21,[2]],
		"/(app)/admin/users": [22,[2]],
		"/(app)/admin/users/[id]": [23,[2]],
		"/(app)/ai": [24,[2]],
		"/(auth)/auth/auth-code-error": [69,[10]],
		"/(app)/calendar": [25,[2]],
		"/(app)/courses": [26,[2,6]],
		"/(app)/courses/mux": [27,[2,6]],
		"/(app)/email": [~28,[2]],
		"/(app)/feed": [~29,[2]],
		"/(auth)/forgot-password": [70,[10]],
		"/(app)/gpt": [30,[2]],
		"/(app)/kanban": [31,[2]],
		"/(app)/linear": [~32,[2,7]],
		"/(app)/linear/issues/[id]": [~33,[2,7]],
		"/(app)/linear/projects/[id]": [~34,[2,7]],
		"/(app)/linear/teams": [~35,[2,7]],
		"/(app)/linear/teams/[id]": [~36,[2,7]],
		"/(auth)/login": [71,[10]],
		"/(app)/logout": [~37,[2]],
		"/(app)/make": [~38,[2]],
		"/(app)/make/notifications": [~39,[2]],
		"/(app)/make/notifications/[id]": [~40,[2]],
		"/(app)/make/organizations": [~41,[2]],
		"/(app)/make/organizations/[id]": [~42,[2]],
		"/(app)/make/scenarios": [~43,[2]],
		"/(app)/make/scenarios/[id]": [~44,[2]],
		"/(app)/map": [45,[2]],
		"/(app)/messages": [46,[2]],
		"/(app)/onboarding": [47,[2,8]],
		"/(app)/playground": [48,[2,9]],
		"/(app)/playground/charts": [49,[2,9]],
		"/(app)/playground/code": [50,[2,9]],
		"/(app)/playground/documents": [51,[2,9]],
		"/(app)/playground/form": [~52,[2,9]],
		"/(app)/playground/jobs/new": [54,[2,9]],
		"/(app)/playground/jobs/[id]/edit": [53,[2,9]],
		"/(app)/playground/super-form": [~55,[2,9]],
		"/(app)/playground/super-form/users": [~56,[2,9]],
		"/(app)/playground/test": [57,[2,9]],
		"/(app)/pricing": [~58,[2]],
		"/(app)/products": [59,[2]],
		"/(app)/products/id": [61,[2]],
		"/(app)/products/[id]": [60,[2]],
		"/(app)/projects": [~62,[2]],
		"/(app)/projects/[id]": [63,[2]],
		"/(app)/realtime": [64,[2]],
		"/(app)/realtime/presence": [65,[2]],
		"/(auth)/register": [72,[10]],
		"/sentry-example": [73],
		"/(app)/stripe/order": [66,[2]],
		"/(app)/stripe/payment": [67,[2]],
		"/(app)/stripe/success": [~68,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';